import {useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom';
import "./scss/App.scss";
import apiFetch from "@wordpress/api-fetch";
import Header from "./js/header/Header";
import Footer from "./js/footer/Footer";
import Content from "./js/configurator/content/Content.jsx";
import {useGlobalState} from "./js/GlobalContext";
import {
  basicFetchRequest, fetchAllDataInBatchFromRESTAPI,
  findSelectedConfigurator,
  generateSessionID,
  parseUrlParamsSingleConfiguration,
  parseUrlParamsQuotationFolder, setCorrectOptionsMeasurementFields,
  addSessionIDToLocalStorage, addToDataLayer, applyAllDataInBatch
} from "./js/functions/functions.jsx";
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/pro-solid-svg-icons'
import SHA256 from 'crypto-js/sha256';
import Topbar from "./js/configurator/employee/TopBar.jsx";


  export default function App({ hubspotUrl, preAllConfigurators, preDesiredOrderStatus, preExtraPriceRules, preHeaderData, preStartContentData }) {
  const {
    configuratorData, setConfiguratorData,
    selectedConfigurator, setSelectedConfigurator,
    priceData, setPriceData,
    colorData, setColorData,
    engineData, setEngineData,
    priceFromFields, setPriceFromFields,
    conditionalLogic, setConditionalLogic,
    activeField, setActiveField,
    fieldsData, setFieldsData,
    quotationFolder, setQuotationFolder,
    headerData, setHeaderData,
    allConfigurators, setAllConfigurators,
    startContentData, setStartContentData,
    configuratorHubspotUrl, setConfiguratorHubspotUrl,
    extraPriceRules, setExtraPriceRules,
    employeeConfigurator, setEmployeeConfigurator
  } = useGlobalState();
  const [completeConfiguration, setCompleteConfiguration] = useState(false);
  const [fetchedReorderObject, setFetchedReorderObject] = useState({});
  const [desiredOrderStatus, setDesiredOrderStatus] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0,0)
  }, []);

  useEffect(() => {
    !!sessionStorage.getItem('employeeConfigurator') && sessionStorage.getItem('employeeConfigurator')
        ? setEmployeeConfigurator(true)
        : setEmployeeConfigurator(false);
  }, [sessionStorage]);

  // Handle dataLayer for GTM - Start page
  useEffect(() => {
    if (!!allConfigurators && Object.keys(allConfigurators).length > 0) {
      // Create layerObject
      const layerData = {
        item_list_name: "Configurator",
        items: [
          { item_name: "Rolluik" },
          { item_name: "Screen" },
          { item_name: "Zonnescherm" }
        ]
      };

      // Append correct data to layerData based on the fetched data
      layerData.items.forEach(item => {
        const configurator = Object.values(allConfigurators).find(configurator => configurator.title === item.item_name);
        if (configurator && configurator.acf.start_fields && configurator.acf.start_fields.start_price) {
          // Remove non-numeric characters from the price string
          const priceString = parseInt(configurator.acf.start_fields.start_price.replace(/[^0-9.]+/g,""));

          // Parse the float and format it to two decimal places
          item.price = parseFloat(priceString).toFixed(2);
        }
      });
      // Check if the layerData is not empty and the page is not localhost(localhost:3030 is missing dataLayer)
      if(!!layerData) {
        addToDataLayer("view_item_list", layerData);
      }
    }
  }, [allConfigurators]);


  useEffect(() => {
    if(!!hubspotUrl) {
      setConfiguratorHubspotUrl(hubspotUrl);
    }
  }, [hubspotUrl]);

  useEffect(() => {
    const handleApiCaching = async (reorderedObject) => {
      // Check if cached data exists
      const cachedData = localStorage.getItem('cachedConfiguratorData');

      if (cachedData) {
        const cachedDataParsed = JSON.parse(cachedData);
        const cachedChecksum = cachedDataParsed.checksum;

        // Calculate SHA-256 checksum for fetched data
        const fetchedChecksum = SHA256(JSON.stringify(reorderedObject)).toString();

        if (cachedChecksum === fetchedChecksum) {
          // Data has not changed, use the cached data
          setAllConfigurators(cachedDataParsed.data);
          setDesiredOrderStatus(true);
          setHeaderData(cachedDataParsed.headerData || {});
          setStartContentData(cachedDataParsed.startContentData || {});

          return true;
        }
      }

      return false;
    };

    if(!!preAllConfigurators && !!preDesiredOrderStatus && !!preExtraPriceRules && !!preHeaderData && !!preStartContentData) {
      setFetchedReorderObject(preAllConfigurators);
      setAllConfigurators(preAllConfigurators);
      setDesiredOrderStatus(preDesiredOrderStatus);
      setExtraPriceRules(preExtraPriceRules);
      setHeaderData(preHeaderData);
      setStartContentData(preStartContentData);
    }

    handleApiCaching(fetchedReorderObject).then((isCached) => {
      if (isCached) {
        addSessionIDToLocalStorage();
      }
    });
  }, []);

  useEffect(() => {
    // UseEffect to handle the URL parameter data.
    if(window.location.search.includes("share=")) {
      const savedUrlData = parseUrlParamsQuotationFolder(window.location.search);
      savedUrlData.then(data => {
        if(!!data && Object.keys(data).length > 0 && !!configuratorHubspotUrl) {
          setQuotationFolder(data.quotationFolderData);
          navigate(`${configuratorHubspotUrl}/offertemap`);
        }
      })
    }
  }, [allConfigurators, window.location.search, configuratorHubspotUrl]);

  const handleConfiguratorData = (data) => {
    if (!!data) {
      setConfiguratorData(data.acf.steps);
      setPriceData(data.priceData);
      setEngineData(data.engineData);
      setSelectedConfigurator(data.title.toLowerCase());

      {/* @TODO: change test-met-headless-configurator page from hubspot to ACF field, so we can gather the page dynamically */}
      navigate(`/${configuratorHubspotUrl}/${data.title.toLowerCase()}`);
    }
  };

  const handleCloseLabel = (e) => {
    if(!!e.target.parentNode.parentNode && e.target.parentNode.parentNode.classList.contains('select-product__label')) {
      const labelElement = e.target.parentNode.parentNode;
      labelElement.style.display = 'none';
    }
  }

  return (
    <>
      {employeeConfigurator && (
          <Topbar />
      )}
      {!!headerData && Object.keys(headerData).length > 0 && (
          <Header data={headerData} selectedConfigurator={selectedConfigurator} />
      )}
      {((Object.keys(allConfigurators).length <= 0) || (window.location.search.includes("share="))) && (
          <>
            <div className="loading">
              <div className="loading-icon"></div>
              <h3>Configurator is aan het laden...</h3>
            </div>
          </>
      )}

      {!completeConfiguration &&
        (Object.keys(allConfigurators).length > 0 && !window.location.search.includes("share=") && (
            <>
              <div className="main__start">
                <div className="full select-product">
                  {Object.values(allConfigurators).length > 0 && Object.values(startContentData).length > 0 && (
                      <>
                          {!!startContentData && startContentData.label.length > 0 && (
                            <div className="select-product__label">
                                {!!startContentData.label && parse(startContentData.label)}
                              <a className="close-button" onClick={(e) => handleCloseLabel(e)}>
                                <FontAwesomeIcon icon={faXmark} />
                              </a>
                            </div>
                          )}
                        <div className="select-product__title">
                          <h2> {startContentData.title}</h2>
                          <p> {startContentData.description} </p>
                        </div>
                      </>
                  )}
                  <div className="all-products">
                    {Object.values(allConfigurators).length > 0 &&
                        Object.values(allConfigurators).map((configurator, index) => (
                            <div
                                className={`product__${configurator.title} product-card`}
                                key={configurator.title + index}
                                onClick={() => handleConfiguratorData(configurator)}
                            >
                              <div className="entry-image animation">
                                <div
                                    className="image animation"
                                    style={
                                      configurator.title === "Rolluik"
                                          ? {
                                            background: `url(https://aanvragen.degalux.com/wp-content/uploads/2023/10/rolluik_anim.png) no-repeat left center`,
                                          }
                                          : configurator.title === "Zonnescherm"
                                              ? {
                                                background: `url(https://aanvragen.degalux.com/wp-content/uploads/2023/11/zonnescherm-anim.png) no-repeat -78px 0% / 64036px`,
                                              }
                                              : configurator.title === "Screen"
                                                  ? {
                                                    background: `url(https://aanvragen.degalux.com/wp-content/uploads/2023/10/screen-sprite.png) no-repeat 0% 0% / 36089px`,
                                                  }
                                                  : null
                                    }
                                />
                                {configurator.title === "Rolluik" && !!configurator.acf['start_fields'] && (
                                    <>
                                      <div className="start-price">
                                        <span>Vanaf</span>
                                        {configurator.acf['start_fields']['start_price']}
                                      </div>

                                      <div className={`usp ${configurator.title}`}>
                                        <h1 className="card-title">{configurator.title}</h1>
                                        {parse(configurator.acf['start_fields']['start_usp'])}
                                      </div>
                                    </>
                                )}
                                {configurator.title === "Zonnescherm" && !!configurator.acf['start_fields'] && (
                                    <>
                                      <div className="start-price">
                                        <span>Vanaf</span>
                                        {configurator.acf['start_fields']['start_price']}
                                      </div>

                                      <div className={`usp ${configurator.title}`}>
                                        <h1 className="card-title">{configurator.title}</h1>
                                        {parse(configurator.acf['start_fields']['start_usp'])}
                                      </div>
                                    </>
                                )}
                                {configurator.title === "Screen" && !!configurator.acf['start_fields'] && (
                                    <>
                                      <div className="start-price">
                                        <span>Vanaf</span>
                                        {configurator.acf['start_fields']['start_price']}
                                      </div>

                                      <div className={`usp ${configurator.title}`}>
                                        <h1 className="card-title">{configurator.title}</h1>
                                        {parse(configurator.acf['start_fields']['start_usp'])}
                                      </div>
                                    </>
                                )}
                              </div>
                              <button
                                  className="card-button"
                                  onClick={() => handleConfiguratorData(configurator)}
                              >
                                {`${configurator.title} samenstellen`}
                              </button>
                            </div>
                        ))}
                  </div>
                </div>
              </div>
              <Footer />
            </>
        ))}
    </>
  );
}
