import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGlobalState } from "../../GlobalContext.jsx";
import { useMediaQuery } from "react-responsive";
import "../../../scss/Topbar.scss";

export default function Topbar(props) {
    const isTabletOrMobile = useMediaQuery({ maxDeviceWidth: 768 });
    const { employeeConfigurator } = useGlobalState();
    const navigate = useNavigate();

    return (
        <>
            {employeeConfigurator && (
                <div className="employee-topbar">
                    <p>Dit is de Medewerkersversie van de Configurator</p>
                </div>
            )}
        </>
    );
}
