import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.jsx';
import { GlobalProvider } from "./js/GlobalContext.jsx";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import QuotationFolder from "./js/configurator/checkout/QuotationFolder.jsx";
import Configurator from "./js/configurator/Configurator.jsx";
import ThankYouPage from './js/configurator/checkout/ThankYouPage.jsx'; // Ensure you have this import
import { createBrowserHistory } from 'history';
import Error from "./js/error-component/Error.jsx";
import { applyAllDataInBatch } from "./js/functions/functions.jsx";
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import SHA256 from "crypto-js/sha256.js";

const bugsnagApiKey = import.meta.env.VITE_BUGSNAG_API_KEY || '';

try {
    Bugsnag.start({
        apiKey: bugsnagApiKey,
        plugins: [new BugsnagPluginReact()]
    })
    BugsnagPerformance.start({ apiKey: bugsnagApiKey });
} catch (err) {
    console.error('Error initializing Bugsnag', err);
}

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const AppWrapper = () => {
    const [hubspotUrl, setHubspotUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [allDataComplete, setAllDataComplete] = useState(false);
    const [preAllConfigurators, setPreAllConfigurators] = useState(null);
    const [preDesiredOrderStatus, setPreDesiredOrderStatus] = useState(null);
    const [preExtraPriceRules, setPreExtraPriceRules] = useState(null);
    const [preHeaderData, setPreHeaderData] = useState(null);
    const [preStartContentData, setPreStartContentData] = useState(null);
    const [isThankYouPage, setIsThankYouPage] = useState(null);
    const [thankYouPath, setThankYouPath] = useState(null);
    const [isQuotationWim, setIsQuotationWim] = useState(false);
    const [isQuotationWimPath, setIsQuotationWimPath] = useState(null);
    const history = createBrowserHistory();

    useEffect(() => {
        const fetchDataAndApply = async () => {
            try {
                const { updatedConfigurators, reorderedObject, sectionData, headerObject, contentData, extraPriceRules } = await applyAllDataInBatch();
                setPreAllConfigurators(reorderedObject);
                setPreDesiredOrderStatus(true);
                setPreExtraPriceRules(extraPriceRules);
                setPreHeaderData(headerObject);
                setPreStartContentData(contentData);

                if (!!headerObject) {
                    const hubspotURI = headerObject['hubspotURI'].split('/').pop();
                    setHubspotUrl(hubspotURI);

                    const urlToCheck = window.location.origin + window.location.pathname;

                    const urlToCheckEmployee = window.location.origin + window.location.pathname + window.location.search;


                    console.log(urlToCheckEmployee, headerObject.hubSpotQuotationWim);
                    console.log("It is true or false: ", urlToCheckEmployee === headerObject.hubSpotQuotationWim);

                    urlToCheck === headerObject.hubspotThankYouURI ? setIsThankYouPage(true) : setIsThankYouPage(false);

                    urlToCheckEmployee === headerObject.hubSpotQuotationWim ? setIsQuotationWim(true) : setIsQuotationWim(false);
                }

                const fetchedChecksum = SHA256(JSON.stringify(reorderedObject)).toString();

                // Update the cache with the new checksum and data
                localStorage.setItem('cachedConfiguratorData', JSON.stringify({
                    timestamp: Date.now(),
                    checksum: fetchedChecksum,
                    data: reorderedObject,
                    headerData: headerObject || null,
                    startContentData: contentData || null,
                }));

            } catch (error) {
                console.error('Error processing data:', error);
            }
        };
        fetchDataAndApply();
    }, []);

    useEffect(() => {
        if (!!preAllConfigurators && !!preDesiredOrderStatus && !!preExtraPriceRules && !!preHeaderData && !!preStartContentData && !!hubspotUrl && isThankYouPage !== null) {
            setAllDataComplete(true);
        }
    }, [preAllConfigurators, preDesiredOrderStatus, preExtraPriceRules, preHeaderData, preStartContentData, hubspotUrl, isThankYouPage]);

    useEffect(() => {
        if(isThankYouPage) {
            const url = new URL(preHeaderData.hubspotThankYouURI);
            setThankYouPath(url.pathname);
            document.getElementById('root').classList.add("thank-you-page")
        }

        if(isQuotationWim) {
            const url = new URL(preHeaderData.hubSpotQuotationWim);
            setIsQuotationWimPath(url.pathname);
            sessionStorage.setItem("employeeConfigurator", isQuotationWim);
            document.getElementById('root').classList.add("quotation-employee")
        }
    }, [isThankYouPage, isQuotationWim]);

    return (
        <>
            {allDataComplete && (
                <>
                    <script>
                        {/* Remove loading spinner from Wordpress */}
                        document.getElementsByClassName("wp-loading")[0]?.remove();
                    </script>
                    <GlobalProvider>
                        <ErrorBoundary FallbackComponent={Error}>
                            <Router basename="/">
                                <Routes>
                                    {isThankYouPage ? (
                                        <Route path={`/${thankYouPath}`} element={<ThankYouPage />} />
                                    ) : (
                                        <>
                                            <Route path={`/${hubspotUrl}/aanvragen`} element={
                                                <QuotationFolder
                                                    hubspotUrl={hubspotUrl}
                                                    preAllConfigurators={preAllConfigurators}
                                                    preDesiredOrderStatus={preDesiredOrderStatus}
                                                    preExtraPriceRules={preExtraPriceRules}
                                                    preHeaderData={preHeaderData}
                                                    preStartContentData={preStartContentData}
                                                />
                                            } />
                                            <Route path={`/${hubspotUrl}/offertemap`} element={
                                                <QuotationFolder
                                                    hubspotUrl={hubspotUrl}
                                                    preAllConfigurators={preAllConfigurators}
                                                    preDesiredOrderStatus={preDesiredOrderStatus}
                                                    preExtraPriceRules={preExtraPriceRules}
                                                    preHeaderData={preHeaderData}
                                                    preStartContentData={preStartContentData}
                                                />
                                            } />
                                            <Route path={`/${hubspotUrl}/rolluik`} element={
                                                <Configurator
                                                    hubspotUrl={hubspotUrl}
                                                    preAllConfigurators={preAllConfigurators}
                                                    preDesiredOrderStatus={preDesiredOrderStatus}
                                                    preExtraPriceRules={preExtraPriceRules}
                                                    preHeaderData={preHeaderData}
                                                    preStartContentData={preStartContentData}
                                                />
                                            } />
                                            <Route path={`/${hubspotUrl}/zonnescherm`} element={
                                                <Configurator
                                                    hubspotUrl={hubspotUrl}
                                                    preAllConfigurators={preAllConfigurators}
                                                    preDesiredOrderStatus={preDesiredOrderStatus}
                                                    preExtraPriceRules={preExtraPriceRules}
                                                    preHeaderData={preHeaderData}
                                                    preStartContentData={preStartContentData}
                                                />
                                            } />
                                            <Route path={`/${hubspotUrl}/screen`} element={
                                                <Configurator
                                                    hubspotUrl={hubspotUrl}
                                                    preAllConfigurators={preAllConfigurators}
                                                    preDesiredOrderStatus={preDesiredOrderStatus}
                                                    preExtraPriceRules={preExtraPriceRules}
                                                    preHeaderData={preHeaderData}
                                                    preStartContentData={preStartContentData}
                                                />
                                            } />
                                            <Route path="*" element={
                                                <App
                                                    hubspotUrl={hubspotUrl}
                                                    preAllConfigurators={preAllConfigurators}
                                                    preDesiredOrderStatus={preDesiredOrderStatus}
                                                    preExtraPriceRules={preExtraPriceRules}
                                                    preHeaderData={preHeaderData}
                                                    preStartContentData={preStartContentData}
                                                />
                                            } />
                                        </>
                                    )}
                                </Routes>
                            </Router>
                        </ErrorBoundary>
                    </GlobalProvider>
                </>
            )}
        </>
    );
};

ReactDOM.createRoot(document.getElementById('root')).render(<AppWrapper />);