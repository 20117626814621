import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGlobalState } from "../../GlobalContext.jsx";
import { useMediaQuery } from "react-responsive";
import "../../../scss/Topbar.scss";

export default function Topbar(props) {
    const isTabletOrMobile = useMediaQuery({ maxDeviceWidth: 768 });
    const { employeeConfigurator, setEmployeeConfigurator } = useGlobalState();
    const navigate = useNavigate();

    const leaveEmployeeVersion = () => {
        // Set the session storage item to indicate the user is leaving the employee configurator
        sessionStorage.setItem("employeeConfigurator", "false");

        // Update the state to reflect the change
        setEmployeeConfigurator(false);

        // Notify the user
        alert("U wordt nu doorgestuurd naar de normale versie van de configurator");

        // Redirect to the home page
        window.location.href = "/";
    };

    return (
        <>
            {employeeConfigurator && (
                <div className="employee-topbar">
                    <p>Dit is de Medewerkersversie van de Configurator</p>
                    <a className={"leave-employee"} onClick={() => leaveEmployeeVersion()}>Verlaat Medewerkerversie</a>
                </div>
            )}
        </>
    );
}
