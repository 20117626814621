import React from 'react';
import Popup from 'reactjs-popup';
import {useState, useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-regular-svg-icons";
import '../../scss/Popup.scss'
import parse from 'html-react-parser';
import GravityForm from "../forms/gravity-forms/index.jsx";
import HubspotShareQuotationFolderForm from "../forms/hubspot-forms/HubspotForm.jsx";
import {ExpandContainer} from "../configurator/accordion/ExpandContainer.jsx";

export default function InfoPopup(props) {
    const {text, trigger, set, setTextParse, link, customWidth, shareByEmail, activeAccordion, accordionData, isEmployee, employeeQuotationID} = props
    const [shareByEmailComplete, setShareByEmailStatus] = useState(false);
    const [fullLoadedForm, setFullLoadedForm] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState(false);


    return (
        <Popup
            className={"initial-popup"}
            open={trigger}
            modal
            onClose={() => set(false)}
            overlayStyle={shareByEmail && !fullLoadedForm ? {display:'none'} : {display:'block'}}
        >
            {close => (
                <div className="modal" onClick={() => set(false)}>
                      <div className="card-container" style={!!customWidth ? {width: customWidth} : {}} onClick={(e) => e.stopPropagation()}>
                          <div className="card-content">
                              <a className="close" onClick={close}><FontAwesomeIcon icon={faXmark} size="lg"
                                                                                    style={{color: "#022955"}}/></a>
                              {!!text && (
                                  <>
                                      {shareByEmail ? (
                                          <>
                                              {fullLoadedForm && (
                                                  <>
                                                      {setTextParse ? parse(text) : <h3>{text}</h3>}
                                                  </>
                                              )}
                                          </>
                                      ) : (
                                          <>
                                              {setTextParse ? parse(text) : <h3>{text}</h3>}
                                          </>
                                      )}
                                  </>
                              )}

                              {shareByEmail && (
                                  isEmployee ? (
                                      <>
                                          <div className="gf-container">
                                              <HubspotShareQuotationFolderForm
                                                  fullLoadedForm={fullLoadedForm}
                                                  setFullLoadedForm={setFullLoadedForm}
                                                  loadingSpinner={true}
                                                  url={JSON.stringify(link)}
                                                  setShareByEmailStatus={setShareByEmailStatus}
                                                  customFormId={"4305291c-f59e-4b6f-9f73-4c1eeb5489bb"}
                                                  employeeQuotationID={employeeQuotationID}
                                              />
                                          </div>
                                      </>
                                  ) : (
                                      <div className="gf-container">
                                          <HubspotShareQuotationFolderForm
                                              fullLoadedForm={fullLoadedForm}
                                              setFullLoadedForm={setFullLoadedForm}
                                              loadingSpinner={true}
                                              url={JSON.stringify(link)}
                                              setShareByEmailStatus={setShareByEmailStatus}
                                              customFormId={"91532817-eb37-4025-8a3a-c947b9d5e895"}
                                          />
                                      </div>
                                  )
                              )}


                              {activeAccordion && accordionData.map((item) => (
                                  <>
                                      <ExpandContainer
                                          title={item.title}
                                          icon_placement="right"
                                          icon={"circle-plus"}
                                          iconExpand={"circle-minus"}
                                      >
                                          {parse(item.content)}
                                      </ExpandContainer>
                                  </>
                              ))}
                          </div>
                      </div>
                </div>
            )}
        </Popup>
    );
}