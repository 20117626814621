import apiFetch from "@wordpress/api-fetch";

const handleConditionalLogic = (forms, set, currentConditionalLogic) => {
    const updatedConditionalLogic = { ...currentConditionalLogic };
    forms.forEach((form) => {
        form['form_fields'].forEach((field) => {
            for (const key in field) {
                if (field.hasOwnProperty(key)) {
                    const item = field[key];
                    if (item['conditional_logic_active']) {
                        const labelKey = item.question_key;

                        if (!updatedConditionalLogic[labelKey]) {
                            updatedConditionalLogic[labelKey] = [];
                        }

                        item['conditional_logic'].forEach((condition) => {
                            let parsedConditionName = isValidJSONString(condition.name) ? JSON.parse(condition.name) : condition.name;
                            let parsedConditionValues = isValidJSONString(condition.values) ? JSON.parse(condition.values) : condition.values;
                            let showHideValue = condition['show_hide'];

                            if (!updatedConditionalLogic[labelKey].some(existingCondition => areConditionsEqual(existingCondition, {name: parsedConditionName, values: parsedConditionValues, show_hide: showHideValue}))) {
                                updatedConditionalLogic[labelKey].push({name: parsedConditionName, values: parsedConditionValues, show_hide: showHideValue});
                            }
                        });
                    }
                    if (item.options) {
                        item.options.forEach((option) => {
                            if (option['conditional_logic_active']) {
                                let labelKey = option['choice_key'];

                                option['conditional_logic'].forEach((condition) => {
                                    let parsedConditionName = isValidJSONString(condition.name) ? JSON.parse(condition.name) : condition.name;
                                    let parsedConditionValues = isValidJSONString(condition.values) ? JSON.parse(condition.values) : condition.values;
                                    let showHideValue = condition['show_hide'];

                                    if (!updatedConditionalLogic[labelKey]) {
                                        updatedConditionalLogic[labelKey] = [];
                                    }

                                    if (!updatedConditionalLogic[labelKey].some(existingCondition => areConditionsEqual(existingCondition, {name: parsedConditionName, values: parsedConditionValues, show_hide: showHideValue}))) {
                                        updatedConditionalLogic[labelKey].push({name: parsedConditionName, values: parsedConditionValues, show_hide: showHideValue});
                                    }
                                });
                            }
                        });
                    }
                    if(item['options_dropdown']) {
                        item['options_dropdown'].forEach((option) => {
                            if (option['conditional_logic_active']) {
                                let labelKey = declareLabelKey(option["option_object"]["post_title"]);
                                let optionKey = option['choice_key'];

                                // @TODO: Double check what this should do
                                if(updatedConditionalLogic[labelKey] && updatedConditionalLogic[labelKey].length >= option['conditional_logic'].length) {
                                    // labelKey += `${optionKey}`;
                                }

                                if (!updatedConditionalLogic[labelKey]) {
                                    updatedConditionalLogic[labelKey] = [];
                                }

                                option['conditional_logic'].forEach((condition) => {
                                    let parsedConditionName = isValidJSONString(condition.name) ? JSON.parse(condition.name) : condition.name;
                                    let parsedConditionValues = isValidJSONString(condition.values) ? JSON.parse(condition.values) : condition.values;
                                    let showHideValue = condition['show_hide'];

                                    if (!!parsedConditionName && !!parsedConditionValues && !updatedConditionalLogic[labelKey].some(existingCondition => areConditionsEqual(existingCondition, {name: parsedConditionName, values: parsedConditionValues, show_hide: showHideValue}))) {
                                        updatedConditionalLogic[labelKey].push({name: parsedConditionName, values: parsedConditionValues, show_hide: showHideValue});
                                    }
                                });

                                if(option['custom_conditional_logic_active']) {
                                    option['custom_conditional_logic'].forEach((condition) => {
                                        let parsedConditionName = isValidJSONString(condition.name) ? JSON.parse(condition.name) : condition.name;
                                        let parsedConditionValues = isValidJSONString(condition.values) ? JSON.parse(condition.values) : condition.values;
                                        let showHideValue = condition['show_hide'];

                                        if (!updatedConditionalLogic[labelKey].some(existingCondition => areCustomConditionsEqual(existingCondition, {name: parsedConditionName, values: parsedConditionValues, show_hide: showHideValue})) && !!parsedConditionName) {
                                            updatedConditionalLogic[labelKey].push({name: parsedConditionName, values: parsedConditionValues, show_hide: showHideValue});
                                        }
                                    });

                                }
                            }
                        });
                    }
                    if(item['options_colors']) {
                        item['options_colors'].forEach((option) => {
                            if (option['custom_conditional_logic_active']) {
                                let objectTypeColor = item['type_color'];
                                if(objectTypeColor === "slats_canvas") objectTypeColor = "canvas"

                                let productTypeKey = item['type_product'].replace("-", "_");

                                const objectKey = productTypeKey + "_" + objectTypeColor;
                                let labelKey = option[objectKey]["post_name"];

                                if (!updatedConditionalLogic[labelKey]) {
                                    updatedConditionalLogic[labelKey] = [];
                                }



                                option['custom_conditional_logic'].forEach((condition) => {
                                    let parsedConditionName = isValidJSONString(condition.name) ? JSON.parse(condition.name) : condition.name;
                                    let parsedConditionValues = isValidJSONString(condition.values) ? JSON.parse(condition.values) : condition.values;
                                    let showHideValue = condition['show_hide'];

                                    if (!updatedConditionalLogic[labelKey].some(existingCondition => areCustomConditionsEqual(existingCondition, {name: parsedConditionName, values: parsedConditionValues, show_hide: showHideValue}))) {
                                        updatedConditionalLogic[labelKey].push({name: parsedConditionName, values: parsedConditionValues, show_hide: showHideValue});
                                    }
                                });
                            }
                            if (option['conditional_logic_active']) {
                                let objectTypeColor = item['type_color'];
                                if(objectTypeColor === "slats_canvas") objectTypeColor = "canvas"

                                let productTypeKey = item['type_product'].replace("-", "_");

                                const objectKey = productTypeKey + "_" + objectTypeColor;
                                let labelKey = option[objectKey]["post_name"];

                                if (!updatedConditionalLogic[labelKey]) {
                                    updatedConditionalLogic[labelKey] = [];
                                }

                                option['conditional_logic'].forEach((condition) => {
                                    let parsedConditionName = isValidJSONString(condition.name) ? JSON.parse(condition.name) : condition.name;
                                    let parsedConditionValues = isValidJSONString(condition.values) ? JSON.parse(condition.values) : condition.values;
                                    let showHideValue = condition['show_hide'];

                                    if (!updatedConditionalLogic[labelKey].some(existingCondition => areConditionsEqual(existingCondition, {name: parsedConditionName, values: parsedConditionValues, show_hide: showHideValue}))) {
                                        updatedConditionalLogic[labelKey].push({name: parsedConditionName, values: parsedConditionValues, show_hide: showHideValue});
                                    }
                                });
                            }
                        });
                    }
                }
            }
        });
    });
    set(updatedConditionalLogic);
};

// Helper function to check if two conditions are equal
const areConditionsEqual = (existingCondition, newCondition) => {
    // Check if both the name and value ids and values match, as well as the show_hide attribute.
    return existingCondition.name.id === newCondition.name.id &&
        existingCondition.name.value === newCondition.name.value &&
        existingCondition.show_hide === newCondition.show_hide &&
        existingCondition.values.id === newCondition.values.id &&
        existingCondition.values.value === newCondition.values.value;
}

const areCustomConditionsEqual = (existingCondition, newCondition) => {
    // Check if both the name and value and show_hide match.
    return existingCondition.name === newCondition.name &&
        existingCondition.values === newCondition.values &&
        existingCondition.show_hide === newCondition.show_hide;
}

const applyConditionalLogicV3 = (fieldsData, conditionalLogic, appliedLogic, setAppliedLogic) => {
    let newAppliedLogic = { ...appliedLogic }; // Create a temporary object to hold the new state
    Object.entries(conditionalLogic).forEach(([key, conditions]) => {
        conditions.forEach((c, cIndex) => {
            let conditionMatch = false;
            if (c.name && c.name.key) {
                const { key } = c.name;
                let fieldKey = key.substring(0, key.lastIndexOf("-"));
                if(fieldKey.includes("bediening-") && !fieldsData[fieldKey]) {
                    fieldKey = fieldKey.split("-")[1];
                }
                const fieldData = fieldsData[fieldKey];
                /*
                    This section of the code handles different types of logic:
                    1. If the logic is intended for a 'type' field, it checks for 'typeKey'. This is because 'type' field is an object in 'fieldsData'.
                    2. If the logic is for regular fields, it checks for a string.
                    3. If the logic is for number fields or other types, it checks for the value directly.
                    4. if else if, for specific cases like 'houzing_size', 'houzing_type', 'height', 'width'. (custom conditional logic)
                */
                if (typeof fieldData === 'object' && fieldData.typeKey) {
                    conditionMatch = fieldData.typeKey === c.values.key;
                } else if (typeof fieldData === 'string') {
                    conditionMatch = (fieldData.toLowerCase() === c.values.key || fieldData === c.values.key);
                } else {
                    conditionMatch = (fieldData === c.values.key);
                }
            } else if (typeof c.name === 'string') {
                conditionMatch = false;
                // Switch to handle different types of conditions
                switch (c.name) {
                    // This case is for houzing size, for rolluik this can be 137, 150, 165, 180, 205
                    case "houzing_size":
                        let filteredKastmaatValue = !!fieldsData['kastmaat'] && fieldsData['kastmaat'].replace(/\D/g, '');
                        conditionMatch = !!filteredKastmaatValue && parseInt(filteredKastmaatValue) === c.values;
                        break;
                    case "houzing_type":
                        // This case is for housing type, for rolluik this can be alu or alu plus(depending on filled width)
                        conditionMatch = !!fieldsData['kast_type'] && fieldsData['kast_type'].toLowerCase() === c.values;
                        break;
                    case "height":
                        // This case is for height.
                        // If the value of this field is lower than the selected width/height in the configurator, the corresponding logic will be applied to it.
                        conditionMatch = c.values <= parseInt(fieldsData['afmeting-hoogte']);
                        break;
                    case "width":
                        // This case is for height.
                        // If the value of this field is lower than the selected width/height in the configurator, the corresponding logic will be applied to it.
                        conditionMatch = c.values <= parseInt(fieldsData['afmeting-breedte']);
                        break;
                }
            }

            if (!conditionMatch) {
                if (!!newAppliedLogic && newAppliedLogic[key]) {
                    if(!!newAppliedLogic[key][cIndex] && newAppliedLogic[key][cIndex] === c) {
                        delete newAppliedLogic[key][cIndex];
                        if (Object.keys(newAppliedLogic[key]).length === 0) {
                            delete newAppliedLogic[key];
                        }
                    }
                }
            } else {
                // Checks if the condition is not already applied
                if (!newAppliedLogic[key] || !newAppliedLogic[key][cIndex] || newAppliedLogic[key][cIndex] !== c) {
                    newAppliedLogic = {
                        ...newAppliedLogic,
                        [key]: {
                            ...newAppliedLogic[key],
                            [cIndex] : c
                        }
                    };
                }
            }
        });
    });

    // Update the state once after the loop has completed
    if (JSON.stringify(newAppliedLogic) !== JSON.stringify(appliedLogic)) {
        setAppliedLogic(newAppliedLogic);
    }
};
const findLowestKey = (object) => {
    /* Function to find the lowest key in an object
          - First, we loop through the object
          - Then, we check if the value is a number
          - If it is, we check if the value is lower than the current lowest value
          - If it is, we set the lowest value to the current value
          - Finally, we return the lowest value (this case it should be 750 for 'rolluik')
    */
    let lowestKey = null;
    let lowestValue = null;

    for (const key in object) {
        if (!isNaN(object[key])) {
            const value = parseInt(object[key]);

            if (lowestValue === null || value < lowestValue) {
                lowestKey = key;
                lowestValue = value;
            }
        }
    }

    return lowestValue;
};

const declareLabelKey = (inputString) => {
    if(!inputString) return;
    let normalizedString = inputString.toLowerCase();
    normalizedString = inputString
        .replace(/–/g, '-')
        .replace(/—/g, '-')
        .toLowerCase();

    // Extract content inside parentheses and concatenate it with the string after the dash if exists
    let contentInsideParenthesesMatch = normalizedString.match(/\((.*?)\)/);
    let contentInsideParentheses = contentInsideParenthesesMatch ? `-${contentInsideParenthesesMatch[1].replace(/\s+/g, '-')}` : '';

    let parts = normalizedString.split(" - ");

    let firstPart = parts[0]
        .replace(/\s*\([^)]*\)\s*/g, '') // Remove parentheses and content within, along with leading/trailing spaces
        .trim() // Trim leading and trailing spaces
        .replace(/\s+/g, '-'); // Replace spaces with dashes

    firstPart += contentInsideParentheses; // Add content inside parentheses to first part

    let secondPart = parts.length > 1
        ? parts[1]
            .replace(/['"]+/g, '') // Remove quotes
            .trim() // Trim leading and trailing spaces
            .replace(/\s+/g, '-') // Replace spaces with dashes
        : '';

    return secondPart.length > 0 ? `${firstPart}-${secondPart}` : firstPart;
};



const capitalizeText = (text) => {
    if(!text) return;
    // Split the text into words
    const words = text.split(' ');

    // Capitalize the first letter of each word
    const capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    });

    // Join the words back together with a space separator
    return capitalizedWords.join(' ');
}

const isValidJSONString = (str) => {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false;
    }
}

const setLocalStorageQuotationFolder = (data) => {
    if(!!data) {
        const localStorageData = {
            quotationFolder: data,
        }
        localStorage.setItem('data_quotation', JSON.stringify(localStorageData));
    }
}

const parseUrlParamsSingleConfiguration = (url) => {
    const urlSearchParams = new URLSearchParams(url);

    if (urlSearchParams.size === 0) {
        return {};
    }

    const configuratorParam = urlSearchParams.get('configurator');
    const priceDataParam = urlSearchParams.get('priceData');
    const configurationParam = urlSearchParams.get('configuration');

    let configurator = null;
    let priceData = null;
    let configuration = null;

    try {
        configurator = configuratorParam ? JSON.parse(configuratorParam) : null;
    } catch (error) {
        console.error('Error parsing configurator JSON:', error);
    }

    try {
        priceData = priceDataParam && isValidJSONString(priceDataParam) ? JSON.parse(priceDataParam) : null;
    } catch (error) {
        console.error('Error parsing priceData JSON:', error);
    }

    try {
        if (configurationParam) {
            const decodedConfigurationParam = decodeURIComponent(configurationParam);
            if (decodedConfigurationParam.trim() !== '{}' && decodedConfigurationParam.trim() !== 'null') {
                configuration = isValidJSONString(decodedConfigurationParam) ? JSON.parse(decodedConfigurationParam) : null;
            }
        }
    } catch (error) {
        console.error('Error parsing configuration JSON:', error);
    }

    return { configurator, priceData, configuration };
};

// Helper function to gather correct URL from the API
const fetchSavedQuotationFolderFromRestAPI = async (sessionID) => {
    let fetchSlug = "degalux-conf/v1/share_quotation/all";
    fetchSlug = basicFetchRequest(fetchSlug);

    // Extract only the share parameter value
    const urlParams = new URLSearchParams(sessionID);
    let correctSessionID = urlParams.get('share');

    try {
        const response = await apiFetch({
            path: fetchSlug,
            method: 'GET',
            headers: {
                // 'Authorization': `Basic ${base64Credentials}`,
                'Content-Type': 'application/json',
            },
        });

        if (response.length > 0) {
            return response.find((data) => data.slug === correctSessionID); // Return the found data
        } else {
            return null; // Return null if not found
        }
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        throw error; // Rethrow the error to handle it elsewhere if needed
    }
};

const parseUrlParamsQuotationFolder = async (sessionID) => {
    const r = await fetchSavedQuotationFolderFromRestAPI(sessionID);

    if (r) {
        const url = r.acf['share_data'];

        const queryString = url.split('?')[1]; // Extract the query string part of the URL

        const urlSearchParams = new URLSearchParams(queryString);
        const quotationFolderData = JSON.parse(urlSearchParams.get('quotationFolder'));

        return { quotationFolderData };
    } else {
        return null; // Handle the case when no data is found
    }
};

// Helper function to find the correct image based on the selected color
const findImage = (images, selectedColor, searchKey, type, isPremium = false) => {
    // Filter images based on link_product and type.typeKey (if they exist)
    let filteredImages = images;
    if (type && type.typeKey) {
        let typeKey = type.typeKey;

        filteredImages = images.filter((data) => {
            const linkProduct = data.link_product.replace("_", "-");
            const typeKeyFormatted = typeKey.replace(" ", "-");
            return linkProduct === typeKeyFormatted;
        });
    }
    // Early return if isPremium is true, search for specific key value 'is_premium' from ACF.
    if (isPremium) {
        if (selectedColor) {
            return filteredImages.find((data) => data[searchKey].color_name === selectedColor && data[searchKey]['is_premium'] === true);
        } else {
            return filteredImages.find((data) => data[searchKey]['is_premium'] === true);
        }
    }

    // Find the image based on the selectedColor and searchKey
    return !!selectedColor && filteredImages.find((data) => data[searchKey].color_name === selectedColor)
        ? filteredImages.find((data) => data[searchKey].color_name === selectedColor)
        : filteredImages.find((data) => !data[searchKey]['is_premium']);

};

// Helper function to find a matching key in colorData
const findMatchingKey = (colorData, soortValue) => {
    let valueToMatch = soortValue;
    return Object.keys(colorData).find((key) => {
        if (key.includes("-")) {
            key = key.replace(/-/g, " ");
        }
        return (key.toLowerCase() === (!!valueToMatch ? valueToMatch.trim() : !!soortValue ? soortValue.toLowerCase().trim() : ""));
    });
};

// Helper function to crop and set an image
const cropAndSetImage = (imageData, x, y, width, height, setImageSetter = false) => {
    return new Promise((resolve, reject) => {
        cropImage(imageData, x, y, width, height)
            .then((croppedImage) => {
                if (!setImageSetter) {
                    resolve(croppedImage);
                } else {
                    setImageSetter(croppedImage);
                    resolve(); // Resolve without a value
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
};

const cropImage = (path, corX, corY, cWidth, cHeight) => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.crossOrigin = "Anonymous"; // Enable cross-origin loading if necessary
        image.src = path;

        image.onload = () => {
            const cropX = corX; // X-coordinate of the top-left corner of the cropped area
            const cropY = corY; // Y-coordinate of the top-left corner of the cropped area
            const cropWidth = cWidth; // Width of the cropped area
            const cropHeight = cHeight; // Height of the cropped area

            const canvas = document.createElement("canvas");
            canvas.width = cropWidth;
            canvas.height = cropHeight;

            const ctx = canvas.getContext("2d");

            // Make the canvas background transparent
            ctx.globalCompositeOperation = "destination-over";
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            ctx.drawImage(
                image,
                cropX,
                cropY,
                cropWidth,
                cropHeight,
                0,
                0,
                cropWidth,
                cropHeight,
            );

            const croppedImage = canvas.toDataURL("image/png");

            resolve(croppedImage);
        };

        image.onerror = (error) => {
            reject(error);
        };
    });
};

const basicFetchRequest = (slug) => {
    // Define your API endpoint URL
    // Since authorization header doesn't work with a proxy server, I manually disabled rest authentication inside the functions.php on the server(backend)
    let apiUrl;
    switch (window.location.hostname) {
        case "localhost":
            apiUrl = `http://localhost:8000/wp-json/${slug}`;
            break;
        case "degalux-configurator-headless.draad.dev":
            apiUrl = `https://degalux-configurator-headless.draad.dev/wp-json/${slug}`;
            break;
        case "degalux.com":
            apiUrl = `https://aanvragen.degalux.com/wp-json/${slug}`;
            // Hardcoded set correct path for fetching data.
            console.log(window.location.pathname);
            console.log(sessionStorage.getItem("employeeConfigurator"));

            if(window.location.pathname === "/offerte-test" || sessionStorage.getItem("employeeConfigurator") === "true") {
                apiUrl = `https://degalux-configurator-headless.draad.dev/wp-json/${slug}`;
            }
            break;
        default:
            apiUrl = `https://aanvragen.degalux.com/wp-json/${slug}`;
            break;
    }

    return apiUrl;
};

const findSelectedConfigurator = (configuratorName, allConfigurators) => {
    for (const singleConf of Object.values(allConfigurators)) {

        if (singleConf.title === capitalizeText(configuratorName)) {
            return singleConf;
        }
    }
    return null;
};

const generateSessionID = () => {
    const timestamp = new Date().getTime(); // Get the current timestamp
    const randomString = Math.random().toString(36).substring(2, 15); // Generate a random string

    return `${timestamp}-${randomString}`;
};

const setCorrectOptionsMeasurementFields = (measurementFields, json) => {
    if(!!measurementFields && Object.keys(measurementFields).length > 0) {
        measurementFields.forEach((field) => {
            const measurementsField = field['form_fields'].find((formField) =>
                formField['form_field']['form_field_type'] === "measurements" && !formField['form_field'].options
            );

            if (measurementsField) {
                // Hardcoded options for 'afmetingen' field.
                measurementsField['form_field'].options = json;
            }
        });
    }
}
const fetchAllDataInBatchFromRESTAPI = async () => {
    const batchRequests = [
        { url: basicFetchRequest("degalux-conf/v1/configurators/all"), key: 'configurator' },
        { url: basicFetchRequest("degalux-conf/v1/colors/all"), key: 'color' },
        { url: basicFetchRequest("wp/v2/price"), key: 'price' },
        { url: basicFetchRequest("degalux-conf/v1/engines/all"), key: 'engine' },
        { url: basicFetchRequest("degalux-conf/v1/options/all"), key: 'options' },
        { url: basicFetchRequest("wp/v2/pages/1420"), key: 'page' },
    ];

    const responses = await Promise.all(
        batchRequests.map(async (request) => {
            const response = await apiFetch({
                path: request.url,
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            return { [request.key]: response };
        })
    );

    return responses;
};

const applyAllDataInBatch = async () => {
    const responses = await fetchAllDataInBatchFromRESTAPI();
    const allData = await Promise.all(
        responses.map(async (response) => {
            return response;
        })
    );

    const updatedConfigurators = {};
    const reorderedObject = {};
    let sectionData;
    let headerObject;
    let contentData;
    let recommendChoice;
    let extraPriceRules;
    let configuratorOrder = [];

    allData.forEach((data) => {
        switch (Object.keys(data)[0]) {
            case "configurator":
                sectionData = data[Object.keys(data)[0]];
                sectionData.forEach((configurator) => {
                    switch (configurator.slug) {
                        case "rolluik":
                            recommendChoice = [
                                {
                                    label: "Breedte",
                                    min: 750,
                                    max: 3800
                                },
                                {
                                    label: "Hoogte",
                                    min: 500,
                                    max: 3400
                                }
                            ];

                            setCorrectOptionsMeasurementFields(configurator.acf.steps, recommendChoice);

                            updatedConfigurators[configurator.slug] = {
                                title: configurator.post_title,
                                acf: configurator.acf,
                                start: configurator.acf['start_fields'],
                                priceData: {},
                                engineData: {}
                            };

                            break;
                        case "zonnescherm":
                            recommendChoice = [
                                {
                                    label: "Breedte",
                                    min: 3000,
                                    max: 5000
                                },
                                {
                                    label: "Hoogte",
                                    min: 2500,
                                    max: 3000
                                }
                            ];

                            setCorrectOptionsMeasurementFields(configurator.acf.steps, recommendChoice);
                            updatedConfigurators[configurator.slug] = {
                                title: configurator.post_title,
                                acf: configurator.acf,
                                priceData: {},
                                engineData: {}
                            };
                            break;
                        case "screen":
                            recommendChoice = [
                                {
                                    label: "Breedte",
                                    min: 750,
                                    max: 4400
                                },
                                {
                                    label: "Hoogte",
                                    min: 500,
                                    max: 4000
                                }
                            ];

                            setCorrectOptionsMeasurementFields(configurator.acf.steps, recommendChoice);
                            updatedConfigurators[configurator.slug] = {
                                title: configurator.post_title,
                                acf: configurator.acf,
                                priceData: {},
                                engineData: {}
                            };
                            break;
                    }
                });
                break;
            case "price":
                sectionData = data[Object.keys(data)[0]];
                let solarData;
                let extraSolarPrice;
                let solarOption;
                let count = 0;
                sectionData.forEach((price) => {
                    switch (price.slug) {
                        case "rolluik":
                            solarData = updatedConfigurators['rolluik'].acf.steps[0]['form_fields'][0]['form_field'].options;
                            extraSolarPrice = price.acf['extra_price_solar'];

                            // Adding the extra price for ' solar rolluik ' option in configurator.acf object.
                            solarOption = solarData.find(item => item.choice_key === "solar rolluik");
                            if (solarOption) {
                                solarOption.price = extraSolarPrice;
                            }

                            updatedConfigurators['rolluik'].priceData = price.acf;
                            break;
                        case "zonnescherm":
                            updatedConfigurators['zonnescherm'].priceData = price.acf;
                            break;
                        case "screen":
                        case "ritsscreen":
                            solarData = updatedConfigurators['screen'].acf.steps[0]['form_fields'][0]['form_field'].options;
                            extraSolarPrice = price.slug === "ritsscreen" && price.acf['extra_price_solar'];
                            solarOption = solarData.find(item => item.choice_key === "solar ritsscreen");

                            if (solarOption && extraSolarPrice) {
                                solarOption.price = extraSolarPrice;
                            }

                            updatedConfigurators['screen'].priceData[count] = price.acf;
                            count++;
                            break;
                    }
                });
                break;
            case "engine":
                sectionData = data[Object.keys(data)[0]];
                sectionData.forEach((engine, index) => {
                    // Check if I can go directly to engine.acf instead of the whole post.
                    updatedConfigurators['rolluik'].engineData[index] = {
                        ['acf']: engine.acf,
                        ['id']: engine["ID"],
                        ['post_title']: engine["post_title"]
                    };
                    updatedConfigurators['zonnescherm'].engineData[index] = {
                        ['acf']: engine.acf,
                        ['id']: engine["ID"],
                        ['post_title']: engine["post_title"]
                    };
                    updatedConfigurators['screen'].engineData[index] = {
                        ['acf']: engine.acf,
                        ['id']: engine["ID"],
                        ['post_title']: engine["post_title"]
                    };
                });
                break;
            case "options":
                sectionData = data[Object.keys(data)[0]];
                extraPriceRules = sectionData['add_rule'];
                headerObject = {
                    header: {
                        mail: sectionData.mail,
                        phone: sectionData['phone_number'],
                        heading: sectionData['product_heading'],
                        menu: sectionData['menu_button_title'],
                        logo: "https://aanvragen.degalux.com/wp-content/uploads/2023/10/Degalux_Logo.svg"
                    },
                    usp: sectionData.usps,
                    hubspotURI: sectionData['hubspot_configurator_link'],
                    hubspotThankYouURI: sectionData['hubspot_thankyou_page_link'],
                    hubSpotQuotationWim: sectionData['hubspot_quotation_wim_link'],
                    postalCodeAPIActive: sectionData['boolean_postalcode_api'],
                    REST: {
                        restApiEnabled: sectionData['secure_rest_api'],
                        restApi: sectionData['rest_api_password'],
                    }
                }
                break;
            case "page":
                sectionData = data[Object.keys(data)[0]];
                contentData = sectionData.acf['start_content'];
                configuratorOrder = sectionData.acf['tiles'];
                break;
        }
    });

    const desiredOrder = configuratorOrder.map((configurator) => configurator.title.toLowerCase());

    desiredOrder.forEach((key) => {
        if (updatedConfigurators.hasOwnProperty(key)) {
            reorderedObject[key] = updatedConfigurators[key];
        }
    });

    return {updatedConfigurators, reorderedObject, sectionData, headerObject, contentData, extraPriceRules};
}
const addSessionIDToLocalStorage = () => {
    if(!!localStorage.getItem('sessionID')) return;
    const sessionID = generateSessionID();
    localStorage.setItem('sessionID', sessionID);
};

const clearEcommerceForEvent = (eventName) => {
    for (let i = 0; i < window.dataLayer.length; i++) {
        const eventData = window.dataLayer[i];
        if (eventData.event === eventName && eventData.ecommerce !== null) {
            eventData.ecommerce = null;
            window.dataLayer.push(eventData);
        }
    }
};

const addToDataLayer = (event, data, source, currentStep = false) => {
    if (!window.dataLayer) return;
    clearEcommerceForEvent(event);
    window.dataLayer.push({ecommerce: null});
    window.dataLayer.push({
        event: event,
        config_source: !!source && !!currentStep ? (source + " {{step " + currentStep + "}}") : !!source ? source : null,
        ecommerce: {
            data
        }
    });
}

const replaceDashToWhitespace = (key, isUnderScore = false) => {
    if(isUnderScore) {
        return key.replace(/[-_]/g, " ");
    }
    return key.replace(/-/g, " ");
};

const mapCartData = (item, selectedConfigurator) => {

    const fieldOrder = [
        'type',
        'uitvoering',
        'soort',
        'plaatsing',
        'afwerking',
        'afmeting-breedte',
        'breedte',
        'afmeting-hoogte',
        'hoogte',
        'uitval',
        'kleur-omkasting-keuze',
        'kleur-lamellen-keuze',
        'kleur-doek-keuze',
        'montage',
        'motor',
        'plaatsing',
        'zender',
    ];

    const newItem = {};
    // Ensure all fields specified in fieldOrder are processed in order
    fieldOrder.forEach((fieldKey) => {
        if (item.hasOwnProperty(fieldKey)) {
            // Apply transformations based on the original field key
            switch (fieldKey) {
                case "afmeting-breedte":
                    newItem["breedte"] = item[fieldKey] + " mm";
                    break;
                case "afmeting-hoogte":
                    newItem[selectedConfigurator !== "zonnescherm" ? "hoogte" : "uitval"] = item[fieldKey] + " mm";
                    break;
                case "kleuren":
                    break; // Skip adding 'kleuren'
                case "kleuren-omkasting-keuze":
                case "kleuren-omkasting-vulkeuze":
                    newItem["kleur-omkasting"] = item[fieldKey];
                    break;
                case "kleuren-lamellen-keuze":
                    newItem["kleur-lamellen"] = item[fieldKey];
                    break;
                case "kleuren-doek-keuze":
                    newItem["kleur-doek"] = item[fieldKey];
                    break;
                default:
                    // For fields not needing special treatment, copy them directly
                    newItem[fieldKey] = item[fieldKey];
                    break;
            }
        }
    });

    // Then, check if there are any additional fields in item not covered by fieldOrder
    Object.keys(item).forEach((key) => {
        if (!newItem.hasOwnProperty(key)) {
            // Apply any necessary transformations for these additional fields
            switch (key) {
                case "afmeting-breedte":
                    newItem["breedte"] = item[key] + " mm";
                    break;
                case "afmeting-hoogte":
                    newItem[selectedConfigurator !== "zonnescherm" ? "hoogte" : "uitval"] = item[key] + " mm";
                    break;
                case "kleuren":
                    break; // Skip adding 'kleuren'
                case "kleuren-omkasting-keuze":
                case "kleuren-omkasting-vulkeuze":
                    newItem["kleur-omkasting"] = item[key];
                    break;
                case "kleuren-lamellen-keuze":
                    newItem["kleur-lamellen"] = item[key];
                    break;
                case "kleuren-doek-keuze":
                    newItem["kleur-doek"] = item[key];
                    break;
                default:
                    // For any remaining fields not needing special treatment, copy them directly
                    newItem[key] = item[key];
                    break;
            }
        }
    });

    return newItem;
};

const triggerAccordion = (e, t) => {
    let wrapper = e.target.closest(".product-data"),
        accordion = wrapper.getElementsByClassName("accordion")[0];

    if (accordion.classList.contains("--collapsed")) {
        accordion.classList.remove("--collapsed");
        accordion.classList.add("--expanded");
        e.target.innerHTML = "<span>" + t[1] + "</span>";
        e.target.classList.add("--active");
    } else {
        accordion.classList.remove("--expanded");
        accordion.classList.add("--collapsed");
        e.target.innerHTML = "<span>" + t[0] + "</span>";
        e.target.classList.remove("--active");
    }

}

const handleImageCrops = (data, set) => {
    if (!!data) {
        const promises = Object.values(data).map((item, index) => {
            let innerImagePromise = cropAndSetImage(item.data.imageData.innerImage, 150, 20, 550, 700);
            let outerImagePromise = cropAndSetImage(item.data.imageData.outerImage, 150, 20, 550, 700);

            if(item.data.nameConfigurator === "zonnescherm") {
                innerImagePromise = cropAndSetImage(item.data.imageData.innerImage, 40, 20, 600, 700);
                outerImagePromise = cropAndSetImage(item.data.imageData.outerImage, 40, 20, 600, 700);
            }

            // Wait for both inner and outer images to be cropped
            return Promise.all([innerImagePromise, outerImagePromise])
                .then(([innerCroppedImage, outerCroppedImage]) => {
                    return {
                        innerImage: innerCroppedImage,
                        outerImage: outerCroppedImage,
                    };
                });
        });

        Promise.all(promises)
            .then((croppedImageSets) => {
                set(croppedImageSets);
            })
            .catch((error) => {
                console.error(error);
            });
    }
}


export {
    handleConditionalLogic,
    findLowestKey,
    // applyConditionalLogic,
    capitalizeText,
    parseUrlParamsSingleConfiguration,
    setLocalStorageQuotationFolder,
    findImage,
    cropAndSetImage,
    findMatchingKey,
    parseUrlParamsQuotationFolder,
    basicFetchRequest,
    findSelectedConfigurator,
    generateSessionID,
    setCorrectOptionsMeasurementFields,
    fetchAllDataInBatchFromRESTAPI,
    addSessionIDToLocalStorage,
    addToDataLayer,
    applyConditionalLogicV3,
    declareLabelKey,
    applyAllDataInBatch,
    replaceDashToWhitespace,
    mapCartData,
    triggerAccordion,
    handleImageCrops
};
